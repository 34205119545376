import {Component} from "react";
import StatusesManager from "./StatusesManager";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

class Terminal extends Component<any, any> {
  private statusesMgr: StatusesManager = new StatusesManager();
  constructor(props: any) {
    super(props);
    this.state = {
      contentHovered: false,
      terminalContent: null,
      terminalContentAbstract: null,
      loading: true
    }
  }

  updateTerminalContent = () => {
    this.statusesMgr.get()
      .then(response => {
        if (!response) {
          throw Error('Not a valid response.');
        }
        const LIMIT: number = 150;
        let length: number = response.length;
        this.setState({
          terminalContent: response,
          terminalContentAbstract: response.slice(0, LIMIT) + (length > LIMIT ? '...' : '')
        })
      })
      .catch(() => {
        let prompt: string = '我想，或许应该使用国际互联网来访问本站...'
        this.setState({
          terminalContent: prompt,
          terminalContentAbstract: prompt
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }
  
  componentDidMount() {
    let prompt: string = '正在寻找言语...'
    this.setState({
      terminalContentAbstract: prompt
    })
    this.updateTerminalContent()
  }

  render() {
    const content: string = this.state.contentHovered ? this.state.terminalContent : this.state.terminalContentAbstract;
    const loadingAnimate: string = this.state.loading ? 'animate-pulse' : '';
    return (
      <div
        className={`hidden p-5 xl:block max-w-xl m-auto ${loadingAnimate}`}
        onMouseEnter={() => this.setState({contentHovered: true})}
        onMouseLeave={() => this.setState({contentHovered: false})}
        onClick={this.updateTerminalContent}
      >
        <span className="inline-block w-full font-serif first-letter:float-left first-letter:text-4xl first-letter:pr-2 first-letter:font-black first-letter:text-red-800">
          {content}
        </span>
      </div>
    )
  }
}

export default Terminal;