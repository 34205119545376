import React from "react";

enum IkaKanji {
    ONE = "以下",
    TWO = "異化"
}

class Slogan extends React.Component<any, any> {
    handleMouseEvent = () => {
        this.props.onIkaToggleChange();
    }
    jumpToIMI = () => {
      window.location.replace('https://imi-blog.pages.dev/');
    }
    render() {
        const kanji: string = this.props.ikaToggleOn ? IkaKanji.TWO : IkaKanji.ONE;
        return (
            <div className="text-center select-none text-[0]">
                <div
                  className="slogan-part"
                  onMouseEnter={this.handleMouseEvent}
                  onMouseLeave={this.handleMouseEvent}
                >
                    <div className="slogan-kanji">{kanji}</div>
                    <div className="slogan-alphabet">/*IKA</div>
                </div>
                <div className="slogan-part cursor-pointer" onClick={this.jumpToIMI}>
                    <div className="slogan-kanji">意味</div>
                    <div className="slogan-alphabet">IMI*/</div>
                </div>
            </div>
        );
    }
}

export default Slogan;