import axios from "axios";

class StatusesManager {
  private TARGET_URL: string = 'aHR0cHM6Ly9vM28uY2EvYXBpL3YyL3NlYXJjaA==';
  private PARAM_Q: string = 'ZnJvbTp2aXZpZHBsYW5rdG9u';
  private PARAM_TOKEN: string = 'ampYMGhUaFRTZlcxbktSR3c1WFFpWmMyaHc2Y3FrT3hYdEJwQ0pJY2lPQQ==';
  private PARAM_TYPE: string = 'statuses';
  private PARAM_LIMIT: number = 40;
  private statusesArray: StatusesArray = new Array<Statuses>();

  private b64_to_utf8 = (str: string): string => {
    return decodeURIComponent(window.atob(str));
  }

  private getRandomInt = (max: number): number =>{
    return Math.floor(Math.random() * max);
  }

  private delHtmlTag = (str: string): string => {
    return str.replace(/<[^>]+>/g,"");
  }

  private fetch = async(offset: number = 0): Promise<any> => {
      return axios.get(this.b64_to_utf8(this.TARGET_URL), {
        params: {
          q: this.b64_to_utf8(this.PARAM_Q),
          type: this.PARAM_TYPE,
          limit: this.PARAM_LIMIT,
          offset: offset * this.PARAM_LIMIT,
        },
        headers: {
          Authorization: "Bearer " + this.b64_to_utf8(this.PARAM_TOKEN)
        },
        timeout: 5 * 1000
      }).then((response: { data: { statuses: Statuses; }; }) => {
        let statuses: Statuses = response.data.statuses;
        if (statuses.length !== 0) {
          this.statusesArray = this.statusesArray.concat(statuses);
          return this.fetch(offset + 1);
        }
      })
  }

  private select = (): Promise<string> => {
    let maxIndex: number = this.statusesArray.length;
    if (maxIndex === 0) {
      throw Error('Invalid statusesArray');
    }
    let at: Statuses | undefined = this.statusesArray?.at(this.getRandomInt(maxIndex));
    return new Promise<string>(resolve => {
      resolve(this.delHtmlTag(at?.content));
    });
  }

  public get = async (): Promise<string> => {
      if (this.statusesArray.length !== 0) {
        return this.select();
      } else {
        return this.fetch().then(this.select);
      }
  }
}

export default StatusesManager;

type Statuses = {[k: string]: any};
type StatusesArray = Array<Statuses>;