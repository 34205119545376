import React, {Component} from 'react';
import Slogan from "./component/Slogan";
import Terminal from "./component/Terminal";

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      ikaToggleOn: false,
    }
    this.handleIkaToggleOnChange = this.handleIkaToggleOnChange.bind(this);
  }

  handleIkaToggleOnChange() {
    this.setState((state: any) => ({
      ikaToggleOn: !state.ikaToggleOn
    }));
  }

  render() {
    return (
      <div className="md:grid grid-cols-4 grid-rows-3 gap-0 h-screen w-screen">
        <div className="md:mt-10 col-start-2 col-span-2">
          <Slogan
            ikaToggleOn={this.state.ikaToggleOn}
            onIkaToggleChange={this.handleIkaToggleOnChange}
          />
        </div>
        <div className="row-start-3 col-start-2 col-span-2 select-none">
          <Terminal/>
        </div>
      </div>
    );
  }
}

export default App;